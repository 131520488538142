import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const Login = () => import("@/views/Login.vue")
//const PaginaIniziale = () => import("@/views/PaginaIniziale.vue")
const MiPresento = () => import("@/views/vetrina/ChiSono/MiPresento.vue")
const PianoStudi = () => import("@/views/vetrina/ChiSono/PianoStudi.vue")
const CittadinanzaAttiva = () =>
  import("@/views/vetrina/ChiSono/CittadinanzaAttiva.vue")
const MassMedia = () => import("@/views/vetrina/ChiSono/MassMedia.vue")

const RicercaDelSe = () => import("@/views/vetrina/ChiSono/RicercaDelSe.vue")

const Spirale = () =>
  import("@/views/vetrina/ChiSono/ricerca_del_se/Spirale.vue")
const LaMiaSpirale = () =>
  import("@/views/vetrina/ChiSono/ricerca_del_se/LaMiaSpirale.vue")
const Numerologia = () =>
  import("@/views/vetrina/ChiSono/ricerca_del_se/Numerologia.vue")

const RassegnaStampa = () => import("@/views/vetrina/RassegnaStampa.vue")

const Handiphone = () => import("@/views/vetrina/Handiphone/PaginaIniziale.vue")
const HandiphoneDescrizione = () =>
  import("@/views/vetrina/Handiphone/Descrizione.vue")
const HandiphoneSpecifiche = () =>
  import("@/views/vetrina/Handiphone/Specifiche.vue")

const Libro = () => import("@/views/vetrina/Libro/PaginaIniziale.vue")
const LibroPresentazioni = () =>
  import("@/views/vetrina/Libro/Presentazioni.vue")
const LibroCommenti = () => import("@/views/vetrina/Libro/Commenti.vue")

const Playlist = () => import("@/views/vetrina/Playlist.vue")

const Contatti = () => import("@/views/vetrina/Contatti.vue")

const CalendarioFilosofico = () =>
  import("@/views/vetrina/CalendarioFilosofico.vue")
const CalendarioFilosoficoRiservato = () =>
  import("@/views/web-app/CalendarioFilosofico.vue")

const Assistenti = () => import("@/views/web-app/BustePaga/Assistenti.vue")
const BustePaga = () => import("@/views/web-app/BustePaga/Buste.vue")

const SuperCategorie = () => import("@/views/web-app/Banca/SuperCategorie.vue")
const Categorie = () => import("@/views/web-app/Banca/Categorie.vue")
const Movimenti = () => import("@/views/web-app/Banca/Movimenti.vue")
const Gestione = () => import("@/views/web-app/Banca/Gestione.vue")
const Tabellone = () => import("@/views/web-app/Banca/Tabellone.vue")

const Compleanni = () => import("@/views/web-app/Compleanni.vue")

const GruppiPassword = () => import("@/views/web-app/Password/Gruppi.vue")
const AccountPassword = () => import("@/views/web-app/Password/Account.vue")

const CondominiCase = () => import("@/views/web-app/Condomini/Case.vue")
const CondominioTipologie = () =>
  import("@/views/web-app/Condomini/Tipologie.vue")
const CondominiScadenze = () => import("@/views/web-app/Condomini/Scadenze.vue")

const Idraulico = () => import("@/views/vetrina/GiocoIdraulico.vue")

const LandingPage = () => import("@/views/vetrina/Landingpage.vue")

const CodiciFrasi = () => import("@/views/web-app/Handiphone/CodiciFrasi.vue")

const Word = () => import("@/views/web-app/Word.vue")

const DistrettiMuscoliBotulino = () =>
  import("@/views/web-app/Butolino/Distretti.vue")
const MuscoliBotulino = () => import("@/views/web-app/Butolino/Muscoli.vue")
const QtyDateBotulino = () => import("@/views/web-app/Butolino/QtyDate.vue")

const Test = () => import("@/views/Test.vue")
const Dashboard = () => import("@/views/web-app/Dashboard.vue")

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Dashboard
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/chisono",
    name: "ChiSono",
    children: [
      {
        path: "mipresento",
        name: "MiPresento",
        component: MiPresento
      },
      {
        path: "pianostudi",
        name: "PianoStudi",
        component: PianoStudi
      },
      {
        path: "cittadinanzaattiva",
        children: [
          {
            path: "",
            name: "CittadinanzaAttiva",
            component: CittadinanzaAttiva
          },
          {
            path: "/chisono/cittadinanzaattiva/mass-media",
            name: "MassMedia",
            component: MassMedia
          }
        ]
      },
      {
        path: "ricercadelse",
        children: [
          {
            path: "",
            name: "RicercaDelSe",
            component: RicercaDelSe
          },
          {
            path: "spirale",
            name: "Spirale",
            component: Spirale
          },
          {
            path: "lamiaspirale",
            name: "LaMiaSpirale",
            component: LaMiaSpirale
          },
          {
            path: "numerologia",
            name: "Numerologia",
            component: Numerologia
          }
        ]
      }
    ]
  },
  {
    path: "/handiphone",
    children: [
      {
        path: "",
        name: "Handiphone",
        component: Handiphone
      },
      {
        path: "descrizione",
        name: "HandiphoneDescrizione",
        component: HandiphoneDescrizione
      },
      {
        path: "specifiche",
        name: "HandiphoneSpecifiche",
        component: HandiphoneSpecifiche
      }
    ]
  },
  {
    path: "/libro",
    children: [
      {
        path: "",
        name: "Libro",
        component: Libro
      },
      {
        path: "presentazioni",
        name: "LibroPresentazioni",
        component: LibroPresentazioni
      },
      {
        path: "commenti",
        name: "LibroCommenti",
        component: LibroCommenti
      }
    ]
  },
  {
    path: "/rassegnastampa",
    name: "RassegnaStampa",
    component: RassegnaStampa
  },
  {
    path: "/calendariofilosofico",
    children: [
      {
        path: "",
        name: "CalendarioFilosofico",
        component: CalendarioFilosofico
      },
      {
        path: "admin",
        name: "CalendarioFilosoficoRiservato",
        component: CalendarioFilosoficoRiservato
      }
    ]
  },
  {
    path: "/playlist",
    name: "Playlist",
    component: Playlist
  },
  {
    path: "/contatti",
    name: "Contatti",
    component: Contatti
  },
  {
    path: "/bustepaga",
    children: [
      {
        path: "",
        name: "BustePaga",
        component: BustePaga
      },
      {
        path: "assistenti",
        name: "Assistenti",
        component: Assistenti
      }
    ]
  },
  {
    path: "/banca",
    children: [
      {
        path: "",
        name: "Banca",
        component: SuperCategorie
      },
      {
        path: "supercategorie",
        name: "SuperCategorie",
        component: SuperCategorie
      },
      {
        path: "categorie",
        name: "Categorie",
        component: Categorie
      },
      {
        path: "movimenti",
        name: "Movimenti",
        component: Movimenti
      },
      {
        path: "gestione",
        name: "Gestione",
        component: Gestione
      },
      {
        path: "tabellone",
        name: "Tabellone",
        component: Tabellone
      }
    ]
  },
  {
    path: "/compleanni",
    name: "DateCompleanni",
    component: Compleanni
  },
  {
    path: "/password",
    children: [
      {
        path: "gruppi",
        name: "GruppiPassword",
        component: GruppiPassword
      },
      {
        path: "account",
        name: "AccountPassword",
        component: AccountPassword
      }
    ]
  },
  {
    path: "/condomini",
    children: [
      {
        path: "case",
        name: "CondominiCase",
        component: CondominiCase
      },
      {
        path: "tipologie",
        name: "CondominiTipologie",
        component: CondominioTipologie
      },
      {
        path: "scadenze",
        name: "CondominiScadenze",
        component: CondominiScadenze
      }
    ]
  },
  {
    path: "/butolino",
    children: [
      {
        path: "distretti",
        name: "DistrettiMuscoliBotulino",
        component: DistrettiMuscoliBotulino
      },
      {
        path: "distretti",
        name: "MuscoliBotulino",
        component: MuscoliBotulino
      },
      {
        path: "muscoli",
        name: "MuscoliBotulino",
        component: MuscoliBotulino
      },
      {
        path: "qtydate",
        name: "QtyDateBotulino",
        component: QtyDateBotulino
      }
    ]
  },
  {
    path: "/gioco",
    name: "GiocoIdraulico",
    component: Idraulico
  },
  {
    path: "/landingpage",
    name: "LandingPage",
    component: LandingPage
  },
  {
    path: "/codicifrasi",
    name: "CodiciFrasi",
    component: CodiciFrasi
  },
  {
    path: "/word",
    name: "Word",
    component: Word
  },
  {
    path: "/test",
    name: "Test",
    component: Test
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
