<template>
  <div>
    <div
      v-if="Token != ''"
      style="
        position: fixed;
        width: 990px;
        color: #fff;
        text-align: center;
        top: 0;
        right: 0;
        margin: 10px;
        height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 10;
        background-color: #abc9bb;
        color: black;
        font-weight: 800;
        border: 0px var(--Bottoni) solid;
      "
    >
      <div style="float: left">
        <b-table :items="ElencoScadenze" :fields="Intestazione"></b-table>
      </div>
      <div style="float: left">
        <table style="width: 500px">
          <tr v-for="(item, index) in CompleanniFuturi" :key="index">
            <td
              :class="item.Test ? 'giornocompleanno' : ''"
              style="width: 150px"
            >
              {{ item.Nome }}
            </td>
            <td
              :class="item.Test ? 'giornocompleanno' : ''"
              style="width: 150px"
            >
              {{ item.Cognome }}
            </td>
            <td :class="item.Test ? 'giornocompleanno' : ''">
              {{ item.Giorno }} {{ MeseDaNumeroANome(item.Mese) }}
              {{ item.Anno }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="contents">
      <div
        style="
          border: 1px black solid;
          margin: 10px;
          padding: 10px;
          margin-bottom: 50px;
          background-color: #abcdef;
        "
      >
        Menu
      </div>
      <div id="links" v-if="Token != ''" class="text-left">
        <router-link :to="{ name: 'CalendarioFilosoficoRiservato' }">
          <h2>Calendario Filosofico</h2>
        </router-link>
        <router-link :to="{ name: 'BustePaga' }">
          <h2>Buste&nbsp;paga</h2>
        </router-link>
        <router-link :to="{ name: 'Tabellone' }">
          <h2>Banca</h2>
        </router-link>
        <router-link :to="{ name: 'CodiciFrasi' }">
          <h2>Handiphone Codici Frasi</h2>
        </router-link>
        <router-link :to="{ name: 'DateCompleanni' }">
          <h2>Compleanni</h2>
        </router-link>
        <router-link :to="{ name: 'AccountPassword' }">
          <h2>Password</h2>
        </router-link>
        <router-link :to="{ name: 'CondominiScadenze' }">
          <h2>Condomini scadenze</h2>
        </router-link>
        <router-link :to="{ name: 'QtyDateBotulino' }">
          <h2>Botulino QtyDate</h2>
        </router-link>
      </div>
    </div>
    <div></div>
    <div style="float: left; width: 100%; min-height: 100vh">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { mapActions, mapGetters } from "vuex"
import MyMixin from "@/helpers/MyMixin"

export default defineComponent({
  name: "App",
  mixins: [MyMixin],
  data() {
    return {
      larghezza: 0,
      Debug: "Debug",
      Token: "",
      NascondiMenu: true,
      CompleanniFuturi: [],
      Intestazione: [
        { key: "IdCasa", label: "Casa" },
        { key: "IdTipologia", label: "Tipologia" },
        "Importo",
        "Data"
      ]
    }
  },
  mounted() {
    //this.DebugMethod()
    if (localStorage.getItem("Token")) {
      //console.log("aa")
      this.Token = localStorage.getItem("Token")
      setTimeout(() => {
        this.Aggiorna()
      }, 100)
    }
    //this.DebugMethod()
    this.Esami()
    this.Articoli()
    this.Frasi()
    this.Landingpage()

    //this.Assistenti()
    //this.BustePaga()
    //this.SuperCategorie()
    //this.Categorie()
    //this.Movimenti()
  },
  methods: {
    ...mapActions({
      Login: "Utente/Login",
      Esami: "PianoStudi/Elenco",
      Articoli: "RassegnaStampa/Elenco",
      Frasi: "CalendarioFilosofico/Elenco",
      Assistenti: "Assistenti/Elenco",
      BustePaga: "BustePaga/Elenco",
      SuperCategorie: "SuperCategorie/Elenco",
      Categorie: "Categorie/Elenco",
      Movimenti: "Movimenti/Elenco",
      ActionCompleanni: "Compleanni/Elenco",
      GruppiPassword: "GruppiPassword/Elenco",
      AccountPassword: "AccountPassword/Elenco",
      CaseCondomini: "CaseCondomini/Elenco",
      TipologieCondomini: "TipologieCondomini/Elenco",
      ScadenzeCondomini: "ScadenzeCondomini/Elenco",
      Landingpage: "Landingpage/Elenco",
      HandiphoneCategorie: "HandiphoneCategorie/Elenco",
      HandiphoneCodiciFrasi: "HandiphoneCodiciFrasi/Elenco",
      DistrettiMuscolari: "DistrettiBotulino/Elenco",
      MuscoliBotulino: "MuscoliBotulino/Elenco",
      QtyDateBotulino: "QtyDateBotulino/Elenco"
    }),
    Aggiorna() {
      this.Login().then(() => {
        setTimeout(() => {
          this.SuperCategorie()
          this.Categorie()
          this.Movimenti()
          this.Assistenti()
          this.BustePaga()
          this.ActionCompleanni()
          this.GruppiPassword()
          this.AccountPassword()
          this.CaseCondomini()
          this.TipologieCondomini()
          this.ScadenzeCondomini()
          this.HandiphoneCategorie()
          this.HandiphoneCodiciFrasi()
          this.DistrettiMuscolari()
          this.MuscoliBotulino()
          this.QtyDateBotulino()
        }, 900)
      })
    },
    DebugMethod() {
      this.larghezza = window.innerWidth
      console.log(localStorage.getItem("Debug"))
      if (!localStorage.getItem("Debug")) {
        const styles = getComputedStyle(document.documentElement)
        const background = styles.getPropertyValue("--Background")
        const menu = styles.getPropertyValue("--Menu")
        document.documentElement.style.setProperty("--xs", background)
        document.documentElement.style.setProperty("--sm", background)
        document.documentElement.style.setProperty("--md", background)
        document.documentElement.style.setProperty("--lg", background)
        document.documentElement.style.setProperty("--xl", background)
        document.documentElement.style.setProperty("--xxl", background)

        document.documentElement.style.setProperty("--xs_dark", menu)
        document.documentElement.style.setProperty("--sm_dark", menu)
        document.documentElement.style.setProperty("--md_dark", menu)
        document.documentElement.style.setProperty("--lg_dark", menu)
        document.documentElement.style.setProperty("--xl_dark", menu)
        document.documentElement.style.setProperty("--xxl_dark", menu)

        document.getElementById("Debug").style.display = "none"
      } else {
        document.documentElement.style.setProperty("--xs", "aqua")
        document.documentElement.style.setProperty("--sm", "blue")
        document.documentElement.style.setProperty("--md", "green")
        document.documentElement.style.setProperty("--lg", "brown")
        document.documentElement.style.setProperty("--xl", "coral")
        document.documentElement.style.setProperty("--xxl", "cadetblue")

        document.documentElement.style.setProperty("--xs_dark", "#006666")
        document.documentElement.style.setProperty("--sm_dark", "#000066")
        document.documentElement.style.setProperty("--md_dark", "#11a00cff")
        document.documentElement.style.setProperty("--lg_dark", "#660000")
        document.documentElement.style.setProperty("--xl_dark", "#b63100")
        document.documentElement.style.setProperty("--xxl_dark", "#cccaa0")
        //--bs-btn-bg: yellow

        document.getElementById("Debug").style.display = "block"
      }
    }
  },
  computed: {
    ...mapGetters({
      Compleanni: "Compleanni/Elenco",
      Elenco: "ScadenzeCondomini/Elenco",
      Case: "CaseCondomini/Elenco",
      Tipologie: "TipologieCondomini/Elenco"
    }),
    ElencoScadenze() {
      /* eslint-disable */
      //this.Collezione = this.Elenco
      let tmp = [] //as Array<TypeItem>
      this.Elenco.forEach((item) => {
        //console.log(item.Data)
        const Data = (new Date(item.Data)).getTime()
        const Oggi = (new Date()).getTime()
        //console.log(Data, Oggi, Data < Oggi)        
        const Casa = this.Case.find((iteminterno) => iteminterno.Id === item.IdCasa)
        const Tipologia = this.Tipologie.find((iteminterno) => iteminterno.Id === item.IdTipologia)
        if (Data > Oggi) {
          tmp.push({
            Id: item.Id,
            IdCasa: Casa ? Casa.Nome : '',
            IdTipologia: Tipologia ? Tipologia.Nome: '',
            Importo: item.Importo,
            Data: this.Data_aaaammgg_ggmmaaaa(item.Data),
            StaPerEssereInserita: false,
            StaPerEssereModificata: false,
            StaPerEssereEliminata: false,
            _rowVariant: Data < Oggi ? "success" : ""
          })
          }
      })
      //this.Collezione = new Proxy(tmp, {})
      //console.log(new Proxy(tmp, {}))
      return (new Proxy(tmp, {}))
    }
  },
  watch: {
    Compleanni() {
      let Oggi = new Date()
      Oggi = new Date(Oggi.setDate(Oggi.getDate() - 1))
      //console.log(Oggi)
      this.Compleanni.forEach((item) => {
        if (new Date(item.DataInglese).getTime() > Oggi.getTime()) {
          this.CompleanniFuturi.push(item)
        }
      })
    }
  },
  components: {}
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  justify-content: left !important;
  margin-left: 10px;
  text-decoration: none !important;
  color: #000 !important;
  padding: 10px;
  border-radius: 45px;
}

a:hover {
  background-color: #abfffe;
}

nav {
  padding: 30px;

  a {
    justify-content: left;
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a {
  display: flex;
}

.giornocompleanno {
  font-size: 22px;
  background-color: yellowgreen;
  color: #0000ff;
  font-weight: 600;
}

.contents {
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100vh;
  bottom: 0;
  //background: #ddd;
}

.contents {
  white-space: nowrap;
  overflow: hidden;
}

h2 {
  font-size: 15px !important;
}
/*.wrapper:hover*/
.contents:hover {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background: #abcdef;
  width: 210px;
}
#links {
  display: none;
  font-size: 4px;
}

.contents:hover #links {
  display: block;
  font-size: 14px;
}
/*
.wrapper {
  background: #ddd;
  display: inline-block;
  padding: 10px;
  height: 100vh;
  width: auto;
}

.label {
  display: inline-block;
  width: 1em;
}

.contents,
.contents .inner {
  display: inline-block;
}*/

.contents {
  white-space: nowrap;
  margin-left: -1em;
  padding-left: 1em;
}

.contents .inner {
  background: #c3c;
  width: 10px;
  overflow: hidden;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
</style>
