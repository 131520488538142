import {
  ref,
  get,
  push,
  update,
  remove,
  child,
  DataSnapshot
} from "firebase/database"
import db from "../../Conn"
const TabellaRef = ref(db, "qtydatebotulino")
type TypeElemento = {
  Id: string
  Data: string
  IdMuscolo: string
  Qty: number
  Parte?: string
}
type TypeCollezione = Array<TypeElemento>
const state = {
  Collezione: [] as TypeCollezione
}
const getters = {
  Elenco: (state: { Collezione: TypeCollezione }) => {
    return state.Collezione
  }
}
const actions = {
  Elenco({ commit }) {
    const Collezione = [] as TypeCollezione
    const snapshot = get(TabellaRef)
    snapshot.then((res: DataSnapshot) => {
      res.forEach((doc: DataSnapshot) => {
        const obj = doc.val()
        const Id = doc.key ? doc.key : ""
        const Data = obj.Data
        const IdMuscolo = obj.IdMuscolo
        const Qty = obj.Qty
        const Parte = obj.Parte
        const Payload: TypeElemento = {
          Id,
          Data,
          IdMuscolo,
          Qty,
          Parte
        }
        //console.log(Payload)
        Collezione.push(Payload)
        commit("Elenco", Collezione)
      })
    })
  },
  Aggiungi(
    { commit },
    Elemento: {
      item: TypeElemento
    }
  ) {
    console.log(commit)
    //console.log('Vuex Assistenti action Aggiungi')
    const data = JSON.parse(JSON.stringify(Elemento))
    const obj: TypeElemento = data.item
    console.log(obj)
    /* eslint-disable */
    push(TabellaRef, obj).then((response: { key }) => {
      console.log(response)
      /*const snapshot = get(child(TabellaRef, response.key))
      const Id = response.key
      //console.log(snapshot)
      /*snapshot.then((response) => {
        //console.log(response)
        //commit("Aggiungi", { Id, ...response.val() })
      })*/
      //console.log(response, commit)
    })
  },
  Aggiorna({ commit }, Elemento: { Id: string; item: TypeElemento }) {
    //console.log(Elemento)
    update(child(TabellaRef, Elemento.Id), Elemento.item).then(() => {
      get(child(TabellaRef, Elemento.Id)).then(() => {
        commit //commit("Aggiorna", { ...response.val(), Id: response.key })
      })
    })
  },
  Elimina({ commit }, Id) {
    remove(child(TabellaRef, Id)).then((response) => {
      console.log(response, commit)
      //commit("Elimina", IdQtyDate)
    })
  }
}

const mutations = {
  Elenco: (
    state: { Collezione: TypeCollezione },
    Collezione: TypeCollezione
  ) => {
    const ordinato = JSON.parse(JSON.stringify(Collezione)).sort(
      (a: TypeElemento, b: TypeElemento) => {
        return new Date(a.Data).getTime() - new Date(b.Data).getTime()
      }
    )
    //console.log(ordinato)
    state.Collezione = ordinato
  },
  Elimina: (state: { Collezione: TypeCollezione }, Id: string) => {
    console.log("mut elimina")
    console.log(state, Id)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
